import { Route, Routes } from "react-router-dom";
import { Notes } from "./pages/Notes";
import { Docs } from "./pages/documentations/Docs";
import { Doc } from "./pages/documentation/Doc";
import { Help } from "./pages/help/Help";
import { PrivateRoute } from "./authorization/PrivateRoute";
import { AuthCallback } from "./authorization/AuthCallback";
import { Login } from "./pages/Login";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Docs />
            </PrivateRoute>
          }
        />
        <Route
          path="/doc"
          element={
            <PrivateRoute>
              <Doc />
            </PrivateRoute>
          }
        />
        <Route
          path="/notes"
          element={
            <PrivateRoute>
              <Notes />
            </PrivateRoute>
          }
        />
        <Route
          path="/help"
          element={
            <PrivateRoute>
              <Help />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
