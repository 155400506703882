import { Navbar } from "../Navbar";

export function Notes() {
  return (
    <div>
      <Navbar />

      <div className="mx-auto flex w-full max-w-4xl flex-col gap-4 px-2 sm:px-4 md:px-16 mt-6 items-center">
        <div className="flex flex-col gap-4">
          <p className="text-slate-800 text-xl">
            Zajrzyj tu w przyszłości. Miejsce przeznaczone na notatki ze
            spotkań.
          </p>
        </div>
      </div>
    </div>
  );
}
