import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getAuthDataFromLocalStorage } from "./AuthCallback";

interface PrivateRouteProps {
  children: React.ReactNode;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const location = useLocation();
  const authData = getAuthDataFromLocalStorage();

  if (authData) {
    const now = new Date().getTime();
    const expirationTime = new Date(
      (parseInt(authData.created_at) + parseInt(authData.expires_in)) * 1000
    ).getTime();

    if (now > expirationTime) {
      console.log("EXPIRED");
      return (
        <Navigate
          to={`/login?redirect=${encodeURIComponent(location.pathname)}`}
        />
      );
    }
  }

  if (!authData) {
    return (
      <Navigate
        to={`/login?redirect=${encodeURIComponent(location.pathname)}`}
      />
    );
  }

  return <>{children}</>;
};
