import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    gql,
} from "@apollo/client";
import {DataService} from "../data_provider";
import {
    DocumentationModel,
    DocumentationGroupModel,
    UserModel,
    DocumentationMenuModel,
} from "../models";
import {getAuthDataFromLocalStorage} from "../../authorization/AuthCallback";

export class GitlabDataImpl implements DataService {
    client: ApolloClient<any>;

    httpLink = new HttpLink({
        uri: "https://git.ledatel.pl/api/graphql",
    });

    authLink = new ApolloLink((operation, forward) => {
        const token = getAuthDataFromLocalStorage()?.access_token;
        // ignore implicitly has an 'any' type
        // @ts-ignore
        operation.setContext(({headers}) => ({
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
            },
        }));

        return forward(operation);
    });

    constructor() {
        this.client = new ApolloClient({
            uri: "https://git.ledatel.pl/api/graphql",
            cache: new InMemoryCache(),
            link: this.authLink.concat(this.httpLink),
        });
    }

    async getFileForDocumentation(id: string, path: string): Promise<string> {
      if (path.startsWith("/")) {
        path = path.substring(1);
      }
        const request = await this.client.query({
            query: gql`
        query {
          project(fullPath: "${id}") {
            repository {
              blobs(paths: ["${path}"]) {
                nodes {
                  rawBlob
                }
              }
            }
          }
        }
      `,
        });
        return request.data["project"]["repository"]["blobs"]["nodes"][0][
            "rawBlob"
            ];
    }

    async getDocumentation(fullPath: string): Promise<DocumentationModel> {
        const request = await this.client.query({
            query: gql`
        query {
          project(fullPath: "${fullPath}") {
            name
            lastActivityAt
            createdAt
            projectMembers {
              edges {
                node {
                  accessLevel {
                    stringValue
                  }
                  user {
                    name
                    avatarUrl
                  }
                }
              }
            }
            repository {
              rootRef
              blobs(paths: ["config.json"]) {
                nodes {
                  rawBlob
                }
              }
            }
          }
        }
      `,
        });

        let files = null;

        try {
            const config = JSON.parse(
                request.data["project"]["repository"]["blobs"]["nodes"][0]["rawBlob"]
            );

            if (config["files"] != null) {
                files = config["files"].map((file: any) => {
                    return new DocumentationMenuModel(file["title"], file["path"]);
                });
            }
        } catch (error) {
            console.log(error);
        }

        return new DocumentationModel(
            fullPath,
            request?.data["project"]["name"],
            new Date(request.data["project"]["createdAt"]),
            new Date(request.data["project"]["lastActivityAt"]),
            request.data["project"]["projectMembers"]["edges"]
                .filter(
                    (pm: any) => pm["node"]["accessLevel"]["stringValue"] !== "GUEST"
                )
                .map((member: any) => {
                    return new UserModel(
                        member["node"]["user"]["name"],
                        member["node"]["user"]["avatarUrl"]
                    );
                }),
            files,
            request.data["project"]["repository"]["rootRef"]
        );
    }

    async getDocumentations(): Promise<DocumentationGroupModel[]> {
        const request = await this.client?.query({
            query: gql`
        query {
          group(fullPath: "docs") {
            name
            descendantGroups {
              edges {
                node {
                  id
                  name
                  projects {
                    nodes {
                      fullPath
                      name
                      lastActivityAt
                      repository {
                        blobs(paths: ["config.json"]) {
                            nodes {
                              rawBlob
                            }
                        }
                      }
                      projectMembers {
                        edges {
                          node {
                            accessLevel {
                              stringValue
                            }
                            user {
                              name
                              avatarUrl
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
        });
        // get users only when accessLevel is "Maintainer"
        return request?.data["group"]["descendantGroups"]["edges"].map(
            (group: any) => {
                return new DocumentationGroupModel(
                    group["node"]["id"],
                    group["node"]["name"],
                    group["node"]["projects"]["nodes"].map((project: any) => {
                        let files: DocumentationMenuModel[] | null = null;

                        try {
                            console.log(group)
                            const config = JSON.parse(
                                project["repository"]["blobs"]["nodes"][0]["rawBlob"]
                            );

                            if (config["files"] != null) {
                                files = config["files"].map((file: any) => {
                                    return new DocumentationMenuModel(file["title"], file["path"]);
                                });
                            }
                        } catch (error) {
                            console.log(error);
                        }
                        return new DocumentationModel(
                            project["fullPath"],
                            project["name"],
                            new Date(project["lastActivityAt"]),
                            new Date(project["lastActivityAt"]),
                            project["projectMembers"]["edges"]
                                .filter(
                                    (pm: any) =>
                                        pm["node"]["accessLevel"]["stringValue"] !== "GUEST"
                                )
                                .map((member: any) => {
                                    return new UserModel(
                                        member["node"]["user"]["name"],
                                        member["node"]["user"]["avatarUrl"]
                                    );
                                }),
                            files,
                            ""
                        );
                    })
                );
            }
        );
    }

    async getUser(): Promise<UserModel> {
        const request = await this.client.query({
            query: gql`
        query {
          currentUser {
            name
            avatarUrl
          }
        }
      `,
        });
        return new UserModel(
            request?.data["currentUser"]["name"],
            request?.data["currentUser"]["avatarUrl"]
        );
    }
}
