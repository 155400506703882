const authEndpoint = "https://git.ledatel.pl/oauth/authorize";
const tokenEndpoint = "https://git.ledatel.pl/oauth/token";

const clientId =
  "7983ea52a04836312bc3749206bbdd3428754ad497441d848dca75fb1260b0cf";
const redirectUri =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "https://f1ad-176-124-190-92.ngrok-free.app/auth/callback"
    : "https://docs.devel.ledatel.pl/auth/callback";

const scopes = [
  "api",
  "read_api",
  "read_user",
  "read_repository",
  "openid",
  "profile",
  "email",
];

export const getAuthorizeHref = (): string => {
  return `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
    "%20"
  )}&response_type=code`;
};

export const getTokenData = async (code: string): Promise<any> => {
  const response = await fetch(tokenEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      client_id: clientId,
      redirect_uri: redirectUri,
      code: code,
      grant_type: "authorization_code",
    }),
  });

  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Failed to fetch access token: ${response.statusText}`);
  }
};

export const refreshGetToken = async (refToken: string): Promise<any> => {
  const response = await fetch(tokenEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      client_id: clientId,
      redirect_uri: redirectUri,
      grant_type: "refresh_token",
      refresh_token: refToken,
    }),
  });

  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Failed to fetch access token: ${response.statusText}`);
  }
};
