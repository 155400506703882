import { GitlabDataImpl } from "./impl/gitlab_data";
import {
  DocumentationGroupModel,
  DocumentationModel,
  UserModel,
} from "./models";

export interface DataService {
  getDocumentation(id: string): Promise<DocumentationModel>;
  getFileForDocumentation(id: string, path: string): Promise<string>;
  getDocumentations(): Promise<DocumentationGroupModel[]>;
  getUser(): Promise<UserModel>;
}

export const dataProvider = new GitlabDataImpl();
