import { Link, useSearchParams } from "react-router-dom";
import { DocumentationModel } from "../../data/models";
import { useCallback, useEffect, useState } from "react";
import { dataProvider } from "../../data/data_provider";
import { classNames, formatDate } from "../../utils";
import {
  ArrowDownOnSquareIcon,
  DocumentIcon,
  FlagIcon,
} from "@heroicons/react/24/outline";
import ReactMarkdown from "react-markdown";
import "../../styles/md.scss";
import { Navbar } from "../../Navbar";
import remarkGfm from "remark-gfm";

export function Doc() {
  const [searchParams] = useSearchParams();
  const [doc, setDoc] = useState<DocumentationModel | null>(null);
  const [selectedFilePath, setSelectedFilePath] = useState<string | null>(null);
  const [mdFile, setMdFile] = useState<string>("");
  const docId = searchParams.get("id");
  const sectionPath = searchParams.get("section");

  const myUrl = new URL(window.location.href);

  const transformLinkUri = (uri: string) => {
    if (uri.includes(".md")) {
      if (uri.startsWith("/")) {
        return `${myUrl.origin}/doc?id=${doc?.fullPath}&section=${uri.replace(
          "/",
          ""
        )}`;
      }
      const currentDir = selectedFilePath?.substring(
        0,
        selectedFilePath.lastIndexOf("/")
      );
      return `${myUrl.origin}/doc?id=${doc?.fullPath}&section=${currentDir}/${uri}`;
    }
    if (uri.startsWith("http") || uri.startsWith("https")) return uri;
    return `https://git.ledatel.pl/${doc?.fullPath}/-/raw/${doc?.branch}${uri}`;
  };

  const transformImagesUri = (uri: string) => {
    if (uri.startsWith("http") || uri.startsWith("https")) return uri;
    if (!uri.startsWith("/")) {
      uri = "/" + uri;
    }
    return `https://git.ledatel.pl/${doc?.fullPath}/-/raw/${doc?.branch}${uri}`;
  };

  const loadFile = useCallback(
    (filePath: string) => {
      setSelectedFilePath(filePath);
      dataProvider
        .getFileForDocumentation(docId ?? "", filePath)
        .then((text) => {
          setMdFile(text);
          searchParams.set("section", filePath);
          window.history.replaceState(null, "", `?${searchParams.toString()}`);
        });
    },
    [docId, searchParams]
  );

  useEffect(() => {
    dataProvider.getDocumentation(docId ?? "").then((doc) => {
      setDoc(doc);
      if (doc.sections == null) return;
      if (sectionPath !== null) {
        loadFile(sectionPath);
      } else {
        var menuItems = doc.sections.filter((d) => d.path !== undefined);
        loadFile(menuItems[0].path ?? "");
      }
    });
  }, [docId, loadFile, sectionPath]);

  function convertChars(value: string): string {
    return value
      .toLowerCase()
      .replaceAll("ą", "a")
      .replaceAll("ć", "c")
      .replaceAll("ę", "e")
      .replaceAll("ł", "l")
      .replaceAll("ń", "n")
      .replaceAll("ó", "o")
      .replaceAll("ś", "s")
      .replaceAll("ź", "z")
      .replaceAll("ż", "z");
  }

  function sendMailToMaintainers() {
    const mails = [];

    for (const editor of doc?.editors ?? []) {
      const firstName = convertChars(editor.name.split(" ")[0]);
      const lastName = convertChars(editor.name.split(" ")[1]);
      mails.push(`${firstName}.${lastName}@ledatel.pl`);
    }
    const mailsWithComma = mails.join(",");
    const mailToLink = `mailto:${mailsWithComma}?subject=Propozycja zmiany w dokumentacji ${doc?.name}&body=Proszę o wprowadzenie następujących zmian w dokumentacji:`;
    window.location.href = mailToLink;
  }

  if (doc === null)
    return (
      <div>
        <Navbar />
        <div className="mx-auto mb-6 mt-6 flex w-full max-w-6xl flex-col gap-8 px-2 sm:px-4 md:px-16">
          <div className="flex flex-col gap-2">
            <p className={"text-sm text-slate-800"}>Trwa wczytywanie...</p>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <Navbar />
      <div className="flex flex-col">
        <div className="flex flex-wrap items-center gap-4 px-6 py-4 shadow-sm">
          <DocumentIcon className="h-8 w-8" />
          <div className="min-w-[160px] flex-1">
            <h1 className="text-2xl">{doc?.name}</h1>
            <p className="text-sm">
              Ostatnia modyfikacja: {formatDate(doc?.updatedAt ?? "")}
            </p>
          </div>
          <div className="no-print flex gap-1 overflow-x-auto">
            {doc?.editors.map((editor) => {
              return (
                <img
                  key={`editor-${editor.name}`}
                  alt={`${editor.name}`}
                  className="h-10 w-10 rounded-full"
                  src={editor.avatarUrl}
                ></img>
              );
            })}
          </div>
          <button
            onClick={() => sendMailToMaintainers()}
            className="no-print bg-white text-orange-700"
          >
            <FlagIcon className="h-6 w-6" />
          </button>
        </div>
        {doc?.sections !== null ? (
          <div className="flex flex-1 flex-wrap">
            <div className="no-print m-4 flex h-full min-w-[250px] flex-col rounded-md border-2 border-slate-200 py-2">
              {doc?.sections.map((section) => {
                if (section.path === undefined) {
                  return (
                    <div key={section.title} className="px-4 py-2">
                      <h1 className="text-sm font-bold text-orange-950">
                        {section.title}
                      </h1>
                    </div>
                  );
                }
                return (
                  <div key={section.path} className="px-4 py-1">
                    <h1
                      onClick={() => {
                        loadFile(section.path ?? "");
                      }}
                      className={classNames(
                        "text-md",
                        "cursor-pointer",
                        "hover:underline",
                        selectedFilePath === section.path ? "font-bold" : null
                      )}
                    >
                      {section.title}
                    </h1>
                  </div>
                );
              })}
            </div>
            <div className="flex-1 overflow-x-auto min-w-[250px]">
              <ReactMarkdown
                children={mdFile}
                remarkPlugins={[remarkGfm]}
                className="document"
                transformLinkUri={transformLinkUri}
                transformImageUri={transformImagesUri}
              />
              <button
                onClick={() => window.print()}
                className="btn no-print fixed bottom-2 right-2"
              >
                <ArrowDownOnSquareIcon className="h-6 w-6" />
              </button>
            </div>
          </div>
        ) : null}
        {doc?.sections == null ? (
          <div className="flex flex-col items-center justify-center gap-2 p-8">
            <h1 className="text-2xl text-slate-950">
              Dokumentacja niekompletna
            </h1>
            <p className="text-slate-900">
              Brakuje pliku{" "}
              <Link to={"/help"}>
                <span className="cursor-pointer font-bold text-orange-950 underline">
                  config.json
                </span>
              </Link>
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
}
