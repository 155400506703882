export class DocumentationModel {
  fullPath: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  editors: UserModel[];
  sections: DocumentationMenuModel[] | null;
  branch: string;

  constructor(
    fullPath: string,
    name: string,
    createdAt: Date,
    updatedAt: Date,
    editors: UserModel[],
    sections: DocumentationMenuModel[] | null,
    branch: string
  ) {
    this.fullPath = fullPath;
    this.name = name;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.editors = editors;
    this.sections = sections;
    this.branch = branch;
  }
}

export class DocumentationMenuModel {
  title: string;
  path: string | undefined;

  constructor(title: string, path: string  | undefined) {
    this.title = title;
    this.path = path;
  }
}

export class DocumentationDataModel {}

export class DocumentationGroupModel {
  id: string;
  name: string;
  docs: DocumentationModel[];

  constructor(id: string, name: string, docs: DocumentationModel[]) {
    this.id = id;
    this.name = name;
    this.docs = docs;
  }
}

export class UserModel {
  name: string;
  avatarUrl: string;

  constructor(name: string, avatarUrl: string) {
    this.name = name;
    this.avatarUrl = avatarUrl;
  }
}
