import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Navbar } from "../../Navbar";
import "../../styles/md.scss";

export function Help() {
  const [mdfile, setMdfile] = useState<string>("");

  useEffect(() => {
    fetch("/help.md")
      .then((response) => response.text())
      .then((text) => {
        setMdfile(text);
      });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="flex flex-col items-center">
        <ReactMarkdown className="document max-w-7xl">{mdfile}</ReactMarkdown>
      </div>
    </div>
  );
}
