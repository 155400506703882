import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getTokenData } from "./oauth_config";

export const AuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");

    if (code) {
      getTokenData(code).then((tokenData) => {
        updateTokenDataInLocalStorage(tokenData);
        navigate("/");
      });
    }
  }, [location.search, navigate]);

  return <div>Logging in...</div>;
};

export const updateTokenDataInLocalStorage = (tokenData: any) => {
  localStorage.setItem(
    "auth",
    JSON.stringify({
      access_token: tokenData.access_token,
      refresh_token: tokenData.refresh_token,
      expires_in: tokenData.expires_in.toString(),
      created_at: tokenData.created_at.toString(),
    })
  );
};

export const getAuthDataFromLocalStorage = (): {
  access_token: string;
  refresh_token: string;
  expires_in: string;
  created_at: string;
} | null => {
  const authDataString = localStorage.getItem("auth");
  if (!authDataString) {
    return null;
  }
  return JSON.parse(authDataString);
};
