import { useEffect, useState } from "react";
import { DocumentationGroupModel } from "../../data/models";
import { DocsItem } from "./DocsItem";
import { dataProvider } from "../../data/data_provider";
import { formatDate } from "../../utils";
import { Link } from "react-router-dom";
import { Navbar } from "../../Navbar";

export function Docs() {
  const [docs, setDocs] = useState<DocumentationGroupModel[] | null>(null);

  useEffect(() => {
    dataProvider.getDocumentations().then((docs) => {
      setDocs(docs);
    });
  }, []);

  if (docs === null)
    return (
      <div>
        <Navbar />
        <div className="mx-auto mb-6 mt-6 flex w-full max-w-6xl flex-col gap-8 px-2 sm:px-4 md:px-16">
          <div className="flex flex-col gap-2">
            <p className={"text-sm text-slate-800"}>Trwa wczytywanie...</p>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <Navbar />
      <div className="mx-auto mb-6 mt-6 flex w-full max-w-6xl flex-col gap-8 px-2 sm:px-4 md:px-16">
        {docs
          .filter((d) => d.docs.length !== 0)
          .map((docGroup) => {
            return (
              <div key={`group-${docGroup.id}`}>
                <div>
                  <div className="mb-2">
                    <h1 className="text-sm font-bold text-orange-950">
                      {"> " + docGroup.name.toUpperCase()}
                    </h1>
                  </div>
                  <div className="flex flex-col gap-2">
                    {docGroup.docs
                      .filter((d) => d.sections != null)
                      .map((doc) => {
                        return (
                          <Link
                            key={doc.fullPath}
                            to={"/doc?id=" + doc.fullPath}
                          >
                            <DocsItem
                              name={doc.name}
                              updatedAt={formatDate(doc.updatedAt)}
                              editors={doc.editors}
                            />
                          </Link>
                        );
                      })}
                    {docGroup.docs.filter((d) => d.sections === null).length !==
                      0 && (
                      <div className="flex flex-col gap-2">
                        <p className={"text-sm text-slate-800"}>
                          Pominięte:
                          {docGroup.docs
                            .filter((d) => d.sections === null)
                            .map((doc) => {
                              const filteredDocs = docGroup.docs.filter(
                                (d) => d.sections === null
                              );
                              return (
                                // if latest element in array, don't add comma
                                <span key={doc.fullPath}>
                                  <Link
                                    key={doc.name}
                                    to={"/doc?id=" + doc.fullPath}
                                  >
                                    <span className={"text-orange-950"}>
                                      {" "}
                                      {doc.name}
                                    </span>
                                  </Link>
                                  {doc !==
                                    filteredDocs[filteredDocs.length - 1] && (
                                    <span>, </span>
                                  )}
                                </span>
                              );
                            })}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
