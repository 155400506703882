import { DocumentIcon } from "@heroicons/react/24/outline";
import { getAuthorizeHref } from "../authorization/oauth_config";

export function Login() {
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center bg-[#192435]">
      <div className="flex flex-col items-center justify-center gap-2 rounded-md bg-white px-10 py-8 shadow-lg">
        <div className="mb-6 flex w-full items-center justify-center">
          <DocumentIcon className="h-16 w-16  " />
          <h1 className="text-xl font-bold ">
            Dokumentacje
            <br />
            Ledatel
          </h1>
        </div>
        <h2 className="text-slate-900">
          Logowanie za pomocą <span className="text-red-800">Gitlab</span>
        </h2>
        <button
          className="btn h-14 w-full cursor-pointer"
          onClick={() => {
            window.location.href = getAuthorizeHref();
          }}
        >
          Zaloguj się
        </button>
        <p className="m-auto text-xs text-slate-600">
          Zabezpieczane przez protokół Gitlab OAuth2.
        </p>
      </div>
    </div>
  );
}
