import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { UserModel } from "../../data/models";

type DocItemType = {
  name: string;
  updatedAt: string;
  editors: UserModel[];
};

export function DocsItem(props: DocItemType) {
  // sprawdź czy props.updatedAt jest starsze od obecnej daty o więcej niż 30 dni

  function isFresh() {
    const now = new Date();
    const lastUpdate = new Date(props.updatedAt);
    const diff = now.getTime() - lastUpdate.getTime();
    const diffInDays = diff / (1000 * 3600 * 24);
    return diffInDays < 30;
  }

  return (
    <div className="flex cursor-pointer items-center rounded-md border-2 border-slate-200 p-4 transition delay-150 ease-in-out hover:shadow-inner">
      <div className={"flex flex-1 flex-row flex-wrap items-center gap-3"}>
        <div className="flex min-w-[200px] flex-1 flex-col">
          <h1 className="flex flex-row items-center gap-1 text-lg">
            {props.name}{" "}
            {isFresh() && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 text-[#e8671b]"
              >
                <path
                  fillRule="evenodd"
                  d="M13.5 4.938a7 7 0 11-9.006 1.737c.202-.257.59-.218.793.039.278.352.594.672.943.954.332.269.786-.049.773-.476a5.977 5.977 0 01.572-2.759 6.026 6.026 0 012.486-2.665c.247-.14.55-.016.677.238A6.967 6.967 0 0013.5 4.938zM14 12a4 4 0 01-4 4c-1.913 0-3.52-1.398-3.91-3.182-.093-.429.44-.643.814-.413a4.043 4.043 0 001.601.564c.303.038.531-.24.51-.544a5.975 5.975 0 011.315-4.192.447.447 0 01.431-.16A4.001 4.001 0 0114 12z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </h1>
          <p className="text-sm text-gray-600">
            Ostatnia modyfikacja: {props.updatedAt}
          </p>
        </div>
        <div className="flex flex-wrap gap-1">
          {props.editors.map((editor) => {
            return (
              <div className={"h-7 w-7"} key={`editor-${editor.name}`}>
                <img
                  alt={`${editor.name}`}
                  data-tooltip-target={`editor-${editor.name}`}
                  key={`editor-${editor.name}`}
                  className="h-7 w-7 rounded-full"
                  src={editor.avatarUrl}
                ></img>
              </div>
            );
          })}
        </div>
      </div>
      <div className="ml-4 h-6 w-6 min-w-[24px]">
        <ChevronRightIcon />
      </div>
    </div>
  );
}
